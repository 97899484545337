import React from "react";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views
import Home from "./views/Home";
import Secondary from "./views/Secondary";
import Login from "./views/Login";
import Signup from "./views/Signup";
import ComingSoon from "./views/ComingSoon";

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute
              exact
              path="/"
              component={Home}
              layout={LayoutDefault}
              analyzer={this.props.analyzer}
            />
            <AppRoute
              exact
              path="/blog"
              component={Secondary}
              layout={LayoutAlternative}
              logEvent={this.props.logEvent}
              analyzer={this.props.analyzer}
            />
            <AppRoute
              exact
              path="/secondary"
              component={Secondary}
              layout={LayoutAlternative}
              analyzer={this.analyzer}
            />
            <AppRoute
              exact
              path="/login"
              component={Login}
              layout={LayoutSignin}
              logEvent={this.props.logEvent}
              analyzer={this.analyzer}
            />
            <AppRoute
              exact
              path="/connexion"
              component={Signup}
              layout={LayoutSignin}
              logEvent={this.props.logEvent}
              analyzer={this.analyzer}
            />
            <AppRoute
              exact
              path="/coming-soon"
              component={ComingSoon}
              logEvent={this.props.logEvent}
              analyzer={this.analyzer}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
