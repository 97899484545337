import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Votre vie devient plus simple",
      paragraph:
        "Ne perds plus ton temps à chercher les bonnes informations, ici tout est selectionné avec soin.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
            >
              <Image
                className="mb-24"
                src={require("./../../assets/images/features-split-icon.svg")}
                alt="Features split icon"
                width={80}
                height={80}
              />
            </SectionHeader>
            <div className={splitClasses}>
              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">Une communauté grandissante</h3>
                  <p className="m-0">
                    Cheveux nappy est fort de plus de 150 000 personnes qui
                    échangent chaque jour sur facebook.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-01",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    // src={require("./../../assets/images/features-split-image.svg")}
                    src={require("./../../assets/images/cheveuxnappy-featuresplit-1.jpg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgOddStyle}>
                    <Image
                      // src={require("./../../assets/images/features-split-top-01.png")}
                      // src={require("./../../assets/images/cheveuxnappy-featuresplit-1.jpg")}
                      alt="Features split top 01"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">Des données vérifiées</h3>
                  <p className="m-0">
                    Les données et les conseils sont vérifiés testés et
                    approuvés par la communauté nappy{" "}
                    <a
                      href="https://www.facebook.com/cheveuxnappy"
                      target="_blank"
                    >
                      @cheveuxnappy
                    </a>
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-02",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    // src={require("./../../assets/images/features-split-image.svg")}
                    src={require("./../../assets/images/cheveuxnappy-featuresplit-2.jpg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                  <div style={imgEvenStyle}>
                    <Image
                      // src={require("./../../assets/images/features-split-top-02.png")}
                      // src={require("./../../assets/images/cheveuxnappy-featuresplit-1.jpg")}
                      alt="Features split top 02"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">Récupérez votre guide ! </h3>
                  <p className="m-0">
                    Après des mois d'essais et d'erreurs, vous vous battez
                    toujours pour comprendre vos cheveux naturels ? Obtenez
                    notre guide complet en 10 parties pour vous aider à passer
                    aux cheveux naturels et à atteindre enfin la croissance.
                  </p>
                  <div className="reveal-from-top" data-reveal-delay="450">
                    <Button tag="a" color="primary" href="/prix" wideMobile>
                      Accès privé
                    </Button>
                  </div>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-01",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    src={require("./../../assets/images/cheveuxnappy-featuresplit-3.jpg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                  <div style={imgOddStyle}>
                    <Image
                      // src={require("./../../assets/images/features-split-top-03.png")}
                      alt="Features split top 03"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgOddStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-6.31%",
  left: "-16.48%",
};

const imgEvenStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-6.31%",
  left: "-1.51%",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
