import React, { useState, useEffect, useRef } from "react";

import customStyle from "../css/comingSoon.module.scss";

const ComingSoon = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("jul 28, 2021 22:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearTimeout(interval.current);
    };
  }, []);

  return (
    <div>
      {/* <!-- Component Code --> */}

      <div className={customStyle.container}>
        <div className={customStyle.background}></div>

        <div className={customStyle.comingSoonText}>
          NOUS ARRIVONS BIENTOT !
        </div>

        <div className={customStyle.countdownWrapper}>
          <div className={customStyle.digitWrapper}>
            <span className={customStyle.digit}>{timerDays}</span>
            <p>Jours</p>
          </div>
          <div className={customStyle.digitWrapper}>
            <span className={customStyle.digit}>{timerHours}</span>
            <p>Heures</p>
          </div>
          <div className={customStyle.digitWrapper}>
            <span className={customStyle.digit}>{timerMinutes}</span>
            <p>Minutes</p>
          </div>
          <div className={customStyle.digitWrapper}>
            <span className={customStyle.digit}>{timerSeconds}</span>
            <p>Secondes</p>
          </div>
        </div>

        <div className={customStyle.btnWrapper}>
          <a href="#" className={customStyle.btn}>
            <span>Qui sommes nous ?</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
