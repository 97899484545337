import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner pt-0",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
      "tiles-wrap illustration-section-08",
      pushLeft && "push-left"
    );

    const sectionHeader = {
      title: "Ne nous croyez pas sur parole ! Essayez !",
      paragraph:
        "Les utilisatrices de cheveuxnappy sont satisfaits de ce qu'elles ont appris ici ",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-01.jpg")}
                        alt="Testimonial 01"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — Cela semblait être une chose difficile mais finalement
                        j'ai réussi à passer le pas grâce à cheveux nappy. Big
                        chop et longueur des cheveux qui dépasse les épaules.
                        Merci !
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Erika M.
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">Nappy depuis 1 an.</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-02.jpg")}
                        alt="Testimonial 02"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — Pour ma fille je cherchais des conseils
                        désespéremments j'ai trouvé cheveux nappy au hasard et
                        maintenant j'ai ce qu'il me faut pour m'occuper de ses
                        cheveux. merci !
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        M. C.
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">Nappy depuis 2 ans.</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-03.jpg")}
                        alt="Testimonial 03"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — Dans une autre vie j'aurais pu passer à coté de ce
                        site. Je me suis abonnée au groupe et j'ai vu qu'il y
                        avait aussi ce site qui est innovant. Je me suis abonné
                        à la formule mensuelle pour tester je ne regrette rien !
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        G. M.
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">Nappy depuis 6 mois.</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
