import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import GA4React from "ga-4-react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { logEvent } from "./firebase/init";

//import './App.css';
import "./assets/scss/style.scss";

const history = createBrowserHistory();

const trackingId = "G-P2PTVCESRW";

const ga4react = new GA4React(trackingId, {}, [], 5000);

const loadAnalyzer = async () => {
  const ga4 = await ga4react.initialize();
  await ga4.pageview("/");
  return ga4;
  // then(
  //   (ga4) => {
  //     ga4.pageview("/");
  //     return ga4;
  //     // ga4.gtag('event','pageview','path') // or your custom gtag event
  //   },
  //   (err) => {
  //     console.error(err);
  //   }
  // );
};

const analyzer = loadAnalyzer();

ReactDOM.render(
  <Router history={history}>
    <App logEvent={logEvent} analyzer={analyzer} />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
