import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Optimisé pour coller au plus près de vous",
      paragraph:
        "Grâce à nos vidéos, nos ebooks, nos articles, nos astuces vous trouverez ce qui est le mieux adapté à vous. Tout récemment nous avons mis au point un algorithme basé sur vos questions réponsess qui permet de vous donner une collection d'outils adaptés à vous.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content"
              width={72}
              height={72}
            />
            <div className={tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      Suivi possible
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Afin de permettre aux filles qui le souhaitent d'être
                      encadrées dans une routine capillaire, nous proposons
                      également un suivi/coaching d'une heure.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-02.svg")}
                        alt="Features tile icon 02"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Coaching vidéo
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Nous vous donnons des conseils en vidéo sur la croissance
                      des cheveux longs, naturels et bouclés, des conseils de
                      beauté, des achats de mode, et je vous partage mes
                      expériences de vie en tête à tête.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-03.svg")}
                        alt="Features tile icon 03"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Contenu mis à jour
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="400"
                    >
                      Il y a beaucoup de conseils donnés précédemment qui sont
                      faux maintenant qu'on connait mieux les cheveux afro.
                      C'est pourquoi nous mettons à jour avec ce qui il y a de
                      plus récent.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-04.svg")}
                        alt="Features tile icon 04"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      Likez et partagez !
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Cheveux nappy la page : 10 000 likes actuellement.
                      N'hésitez pas à partager votre connaissance fraîchement
                      acquise à vos proches.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-05.svg")}
                        alt="Features tile icon 05"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Contenu gratuit et privé
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Le contenu gratuit est disponible sur les diverses pages
                      du site. Le contenu privé est indiqué d'un cadenas.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-06.svg")}
                        alt="Features tile icon 06"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Dormez tranquille !
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="400"
                    >
                      Suivez nos conseils, articles et astuces, coaching adaptés
                      à vous et laissez faire la magie au fur et à mesure
                      doucement mais sûrement !
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
