import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Switch from "../elements/Switch";
import Button from "../elements/Button";

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false,
};

class Pricing extends React.Component {
  state = {
    priceChangerValue: "1",
    priceInput: {
      // 0: "1,000",
      // 1: "1,250"
    },
    priceOutput: {
      plan1: {
        0: ["€", "10", "1 mois.", "Ebook + articles"],
        1: ["€", "29", "1 an.", "Ebook + articles"],
      },
      plan2: {
        0: ["€", "10", "1 mois.", "Conseils"],
        1: ["€", "29", "1 an.", "Conseils"],
      },
      plan3: {
        0: ["€", "19", "1 mois", "Conseils"],
        1: ["€", "45", "1 an.", "Conseils"],
      },
    },
  };

  slider = React.createRef();
  sliderValue = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      this.slider.current.setAttribute("min", 0);
      this.slider.current.setAttribute(
        "max",
        Object.keys(this.state.priceInput).length - 1
      );
      this.thumbSize = parseInt(
        window
          .getComputedStyle(this.sliderValue.current)
          .getPropertyValue("--thumb-size"),
        10
      );
      this.handleSliderValuePosition(this.slider.current);
    }
  }

  handlePricingSwitch = (e) => {
    this.setState({ priceChangerValue: e.target.checked ? "1" : "0" });
  };

  handlePricingSlide = (e) => {
    this.setState({ priceChangerValue: e.target.value });
    this.handleSliderValuePosition(e.target);
  };

  handleSliderValuePosition = (input) => {
    const multiplier = input.value / input.max;
    const thumbOffset = this.thumbSize * multiplier;
    const priceInputOffset =
      (this.thumbSize - this.sliderValue.current.clientWidth) / 2;
    this.sliderValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
  };

  getPricingData = (values, set) => {
    return set !== undefined
      ? values[this.state.priceChangerValue][set]
      : values[this.state.priceChangerValue];
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "pricing section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "pricing-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Tarifcation accès privé",
      paragraph:
        "3 types d'accès privé 10 jours articles, conseils et astuces ou accès total.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content invert-color"
            />
            {pricingSwitcher && (
              <div className="pricing-switcher center-content invert-color">
                <Switch
                  checked={this.state.priceChangerValue === "1" ? true : false}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Accès pendant 1 an."
                >
                  Accès pendant 1 mois
                </Switch>
              </div>
            )}
            {pricingSlider && (
              <div className="pricing-slider center-content invert-color">
                <label className="form-slider">
                  <span className="mb-16">How many users do you have?</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handlePricingSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="pricing-slider-value">
                  {this.getPricingData(this.state.priceInput)}
                </div>
              </div>
            )}
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan1, 1)}
                        </span>{" "}
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan1, 0)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        Accès illimité pendant{" "}
                        {this.getPricingData(this.state.priceOutput.plan1, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        {this.getPricingData(this.state.priceOutput.plan1, 3)}
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Accès à tous les ebooks</li>
                        <li className="is-checked">
                          Accès à tous les articles
                        </li>
                        <li>Accès limité aux conseils</li>
                        <li>Aucun accès à l'algorithme de conseil</li>
                        <li>Aucun accès au coaching et vidéos</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="primary"
                      wide
                      href="https://www.facebook.com/cheveuxnappy"
                    >
                      Choisir
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan2, 1)}
                        </span>{" "}
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan2, 0)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        Accès illimité pendant{" "}
                        {this.getPricingData(this.state.priceOutput.plan2, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        {this.getPricingData(this.state.priceOutput.plan2, 3)}
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">
                          Accès à tous les conseils
                        </li>
                        <li className="is-checked">
                          Accès à l'algorithme de conseil
                        </li>
                        <li className="is-checked">Accès aux recettes</li>
                        <li>Pas d'accès aux ebooks</li>
                        <li>Pas d'accès aux articles</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="primary"
                      wide
                      href="https://www.facebook.com/cheveuxnappy"
                    >
                      Choisir
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan3, 1)}
                        </span>{" "}
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan3, 0)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        Accès illimité pendant{" "}
                        {this.getPricingData(this.state.priceOutput.plan3, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        All inclusive!<br></br>Ebook + conseils + articles +
                        vidéo
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">
                          Formule Ebook + Formule conseil
                        </li>
                        <li className="is-checked">
                          Accès à l'algorithme de conseil
                        </li>
                        <li className="is-checked">Accès aux recettes</li>
                        <li className="is-checked">
                          1 crédit de coaching par mois
                        </li>
                        <li className="is-checked">Accès aux articles</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="primary"
                      wide
                      href="https://www.facebook.com/cheveuxnappy"
                    >
                      Choisir
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
