import React from "react";
import { Route } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  logEvent,
  analyzer,
  ...rest
}) => {
  Layout =
    Layout === undefined
      ? (props) => <React.Fragment>{props.children}</React.Fragment>
      : Layout;

  analyzer = analyzer == undefined ? console.log : analyzer;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} logEvent={logEvent} analyzer={analyzer} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
