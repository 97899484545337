import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAFkTecg19m3pvGpZfcvswe-tPl3zVmkUM",
  authDomain: "cheveuxnappy20.firebaseapp.com",
  databaseURL: "https://cheveuxnappy20-default-rtdb.firebaseio.com",
  projectId: "cheveuxnappy20",
  storageBucket: "cheveuxnappy20.appspot.com",
  messagingSenderId: "177180324033",
  appId: "1:177180324033:web:4661864bd4bbad84196440",
  measurementId: "G-D106RCST6P",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const { logEvent } = firebase.analytics();

console.log("analytics");
export default firebase;
